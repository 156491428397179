/* eslint-disable */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
// import { ssrWindow } from 'ssr-window';
import { showMessage } from './status'
import { DEFAULT_CHAINID } from '../config'
// import { LOCAL_STORAGE_KEY } from '../constants/domain'
// import { useEffect, useState } from 'react'

const ENV = process.env.NODE_ENV

// function useBaseURL() {
//   const [baseURL, setBaseURL] = useState(process.env.NEXT_PUBLIC_API_URL) // 默认值

//   useEffect(() => {
//     if (process.env.NODE_ENV !== 'development') {
//       setBaseURL(window.location.origin + '/api/swap')
//     }
//   }, [])

//   return baseURL
// }

// function getBaseURL() {
//   if (typeof window !== 'undefined') {
//     return window.location.origin + '/api/swap';
//   }
// }
// let chainId
// try {
//   chainId = ssrWindow.location.search.split("=")[1]
// } catch (error) {
//   chainId = ssrWindow.location.search.split("=")[1]
// }

// console.log(chainId, 'chainIdchainId')

// let BASE_URL = ''
// const BASE_URL = ENV === 'development' ? 'http://192.168.50.2:10008/api/swap' : 'https://api.noahark.pro/api/swap'
// const BASE_URL = ENV === 'development' ? process.env.NEXT_PUBLIC_API_URL + '/swap' : useBaseURL()
let chainIds = DEFAULT_CHAINID.toString()
if (typeof window !== 'undefined' && window.localStorage) {
  chainIds = localStorage.getItem('chainId')
}

let BASE_URL = process.env.NEXT_PUBLIC_API_URL + '/swap'

if (chainIds === '839999') {
  BASE_URL = process.env.NEXT_PUBLIC_API_URL_EXSAT + '/exsat/swap'
}
// const BASE_URL = ENV === 'development' ? 'https://api.noahark.pro/api/swap' : 'https://api.noahark.pro/api/swap'
// if (ENV === 'development') {s
//   BASE_URL = 'http://192.168.50.2:10008/api/swap'
//   if (Number(chainId) === 15557) {
//     BASE_URL = 'http://192.168.50.2:10008/api/swap'
//   } else if (Number(chainId) === 17777) {
//     BASE_URL = 'https://testapi.noahark.pro/api/swap'
//   }
// } else {
//   BASE_URL = 'https://testapi.noahark.pro/api/swap'
// }

// 返回res.data的interface
export interface IResponse {
  code: number | string
  data: any
  message: string
}

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    // chainid: 15557,
    // chainid: 17777,
    chainid: chainIds,
  },
})

// axios实例拦截响应
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status === 200) {
      return response
    }
    // showMessage(response.status || 400)
    return response
  },
  // 请求失败
  (error: any): any => {
    // return
    // const { toastError } = useToast()
    // const { response } = error
    // console.log(response, 'response')
    // if (response) {
    //   // 请求已发出，但是不在2xx的范围
    //   showMessage(response.status || 400)
    //   return Promise.reject(response.data)
    // }
    // eslint-disable-next-line prefer-promise-reject-errors
    return {
      code: 0,
      message: 'Network Error!',
      data: [],
    }
    // return Promise.reject({
    //   code: 400,
    //   message: 'Network Error!',
    // })
  },
)

// axios实例拦截请求
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    return config
  },
  (error: any) => {
    console.log(error, 'error')
    return Promise.reject(error)
  },
)

// export function usePost<T = unknown>(url?: string, options?: RequestInit): FetchState<T> {
//   if (url && url.indexOf(BASE_URL) < 0) {
//     url = BASE_URL + url
//   }
//   const account = localStorage.getItem('account')
//   const token = localStorage.getItem('app_token')
//   if (!options) {
//     options = {}
//   }
//   options.headers = {
//     ...options.headers,
//     account: account ? account : '',
//     token: token ? token : '',
//   }
//   return useFetchPost(url, options)
// }
