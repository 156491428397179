/* eslint-disable */
import { axiosInstance } from 'service'
import { DEFAULT_CHAINID } from '../config'

// import { useEffect, useState } from 'react'

// function useBaseURL() {
//   const [baseURL, setBaseURL] = useState(process.env.NEXT_PUBLIC_API_URL) // 默认值

//   useEffect(() => {
//     if (process.env.NODE_ENV !== 'development') {
//       setBaseURL(window.location.origin + '/api')
//     }
//   }, [])

//   return baseURL
// }

// function getBaseURL() {
//   if (typeof window !== 'undefined') {
//     return window.location.origin + '/api';
//   }
// }

export default function chainUtils(chainId = 17777) {
  // if (chainId === 15557) {
  //   if (process.env.NODE_ENV === 'development') {
  //     return {
  //       baseURL: 'http://192.168.50.2:10008/api',
  //       chainid: 15557,
  //     }
  //   } else {
  //     return {
  //       baseURL: 'https://testapi.noahark.pro/api',
  //       chainid: 15557,
  //     }
  //   }
  //   // axiosInstance.defaults.headers['chainid'] = 15557
  // } else if (process.env.NODE_ENV === 'development') {
  //   return {
  //     // baseURL: 'http://192.168.50.2:10008/api/swap',
  //     baseURL: 'https://api.noahark.pro/api',
  //     chainid: 17777,
  //   }
  // } else {
  //   return {
  //     baseURL: 'https://api.noahark.pro/api',
  //     chainid: 17777,
  //   }
  // }
  let chainIds = DEFAULT_CHAINID.toString()
  if (typeof window !== 'undefined' && window.localStorage) {
    chainIds = localStorage.getItem('chainId')
  }

  let BASE_URL = ''
  if (process.env.NODE_ENV === 'development') {
    BASE_URL = chainIds !== '839999' ? process.env.NEXT_PUBLIC_API_URL : process.env.NEXT_PUBLIC_API_URL_EXSAT
  } else {
    BASE_URL = typeof window !== 'undefined' ? window.location.origin + '/api' : ''
  }
  // let chainIds = process.env.NODE_ENV === 'development' ? '15557' : '17777'
  return {
    // baseURL: process.env.NODE_ENV === 'development' ? process.env.NEXT_PUBLIC_API_URL : useBaseURL(),
    // baseURL: process.env.NEXT_PUBLIC_API_URL,
    baseURL: BASE_URL,
    // chainid: process.env.NEXT_PUBLIC_CHAIN_ID,
    chainid: chainIds,
  }
}
