export var DEFAULT_CHAINID = 839999;
export var links2 = [
    {
        label: "noah80",
        icon: "tab1",
        // href: "/swap",
        items: [
            {
                label: "noah11",
                icon: "tab1",
                href: "/swap"
            },
            {
                label: "noah37",
                icon: "tab2",
                href: "/liquidity"
            }, 
        ]
    }, 
];
export var links = [
    // {
    //   label: "Home",
    //   icon: "HomeIcon",
    //   href: "/",
    // },
    // {
    //   label: "Trade",
    //   icon: "TradeIcon",
    //   items: [
    //     {
    //       label: "Exchange",
    //       href: "https://exchange.noahark.pro",
    //     },
    //     {
    //       label: "noah37",
    //       href: "https://exchange.noahark.pro/#/pool",
    //     },
    //   ],
    // },
    {
        label: "noah80",
        icon: "tab1",
        // href: "/swap",
        items: [
            {
                label: "noah11",
                icon: "tab1",
                href: "/swap"
            },
            {
                label: "noah37",
                icon: "tab2",
                href: "/liquidity"
            }, 
        ]
    },
    // 行情
    {
        label: "noah62",
        icon: "tab4",
        href: "/quotation"
    },
    // 农场
    {
        label: "noah281",
        icon: "farm-route",
        href: "/farms"
    },
    // 治理
    {
        label: "noah408",
        icon: "governance-route",
        // href: "/pledge"
        items: [
            // 质押
            {
                label: "noah302",
                icon: "tab5",
                href: "/pledge"
            },
            // 投票
            {
                label: "noah424",
                icon: "tab5",
                href: "/vote"
            },
            // 贿赂--->激励
            {
                label: "noah371",
                icon: "tab5",
                href: "/incentive"
            },
            // 提案
            {
                label: "noah562",
                icon: "tab5",
                href: "/proposal"
            }, 
        ]
    },
    // 奖励
    {
        label: "noah307",
        icon: "reward-route",
        href: "/reward"
    },
    {
        label: "noah548",
        icon: "bridge",
        href: "/bridge"
    },
    // 空投
    // {
    //   label: "noah243",
    //   icon: "tab5",
    //   href: "/airdrop",
    // },
    // 分析
    {
        label: "noah192",
        icon: "tab3",
        href: "/info"
    }, 
];
export var socialsMore = [
    {
        label: "noah183",
        id: "Network",
        imgUrl: "/images/small-img/network.png"
    },
    {
        label: "noah101",
        id: "Language",
        imgUrl: "/images/small-img/language.png"
    },
    {
        label: "noah485",
        id: "Rpc",
        imgUrl: "/images/small-img/network.png"
    },
    {
        label: "noah102",
        id: "Docs",
        imgUrl: "/images/small-img/doc.png"
    }, 
];
export var socials = [
    // {
    //   label: "Network",
    //   id: "Network",
    //   icon: "",
    //   href: "",
    //   imgUrl: "/images/small-img/network.png"
    // },
    // {
    //   label: "Network",
    //   icon: "Network",
    //   href: "111",
    // },
    {
        id: 1,
        label: "Telegram",
        icon: "TelegramIcon",
        href: "https://t.me/noahevm"
    },
    // {
    //   label: "Discord",
    //   icon: "DiscordIcon",
    //   href: "https://twitter.com/noahswap",
    // },
    {
        id: 2,
        label: "Twitter",
        icon: "TwitterIcon",
        href: "https://twitter.com/noah_defi/"
    },
    {
        id: 3,
        label: "Medium",
        icon: "MediumIcon",
        href: "https://medium.com/@noahdefi"
    },
    {
        id: 4,
        label: "GeckoTerminal",
        icon: "MediumIcon",
        href: "https://www.geckoterminal.com/eos-evm/noahswap/pools",
        imgUrl: "/images/small-img/Gecko-logo.png"
    },
    {
        id: 5,
        label: "CoinMarketCap",
        icon: "MediumIcon",
        href: "https://coinmarketcap.com/exchanges/noah/",
        imgUrl: "/images/small-img/cmc-logo.png"
    },
    {
        id: 6,
        label: "Discord",
        icon: "Discord",
        href: "https://discord.com/invite/AVYHANTbU2",
        imgUrl: "/images/small-img/Discord.png"
    }, 
];
export var MENU_HEIGHT = 64;
export var MENU_ENTRY_HEIGHT = 48;
export var SIDEBAR_WIDTH_FULL = 200;
export var SIDEBAR_WIDTH_REDUCED = 56;
